import Typography from 'typography'

const typography = new Typography({
  baseFontSize: '18px',
  baseLineHeight: 1.666,
  blockMarginBottom: 0.5,
  headerFontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'],
  headerWeight: 'lighter',
  bodyFontFamily: ['Open Sans'],
  bodyColor: "#DDD",
  headerColor: "#ee531b",
});

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
